<template>
    <pagination title="Order Items" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" header-colour-class="bg-transparent" :withEditIcon="false" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Description
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Code</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Due Date</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Qty</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Unit</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>
        </template>

        <template v-slot:items>

            <create-form-new-order-items @change="change" :ref="'newOrderItems'" :newItemAction="createItemAction"></create-form-new-order-items>

        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import OrderItemRow from "@/v3/components/OrderItemRow.vue";
import NewOrderItems from "@/v3/components/NewOrderItems.vue";
import CreateFormNewOrderItems from "@/v3/components/CreateFormNewOrderItems.vue";

export default {
    inject: ["$validator"],
  name: 'NewOrderItemsPagination',
    components: {CreateFormNewOrderItems, NewOrderItems, OrderItemRow, PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
      updateActionData:{
        type: Object
      },
      editing: {
        type: Boolean,
          default: null
      },
      createItemAction:{
        type: Object,
          default:null
      }
  },
    computed:{
        orderItemEntities() {
            if (!this.entity) {
                return null;
            }
            return this.entity.entities.filter(entity => entity.rels.contains('order_item'));
        },
        isDelivered(){
            return this.entity.properties.get('is_delivered');
        }
    },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      },
        async updateItems(action) {
            let updateItemPromises = [];
            const newItems = await this.$refs.newOrderItems.addNewItems(action);
            await newItems.forEach(function (promise) {
                updateItemPromises.push(promise);
            })
            return await Promise.all(updateItemPromises);
        },
        change() {
            this.$emit('change');
        },
        updateRow(row){
            return row.update().catch(error => {
                throw {
                    error: error,
                    scope: 'order-item-row-' + index
                };
            });
        },
    }
}
</script>
