<template>
    <div class="cursor-default">
        <custom-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colour-type="buttonType">
            {{title ? title : action.title}} <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/></svg>
        </custom-button>

      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>
        <portal to="destination">
        <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title"
                    :confirmationString='`Are you sure you want to \"${action.title}\"`'
                    @removeFromDisplay="displayModal = false" @perform="perform()" :confirm-button-text="title ? title : action.title">
            <div class="grid grid-cols-2 gap-2">
                <div class="grid grid-cols-2 auto-rows-min">
                    <collapsable-header class="col-span-2" :collapsable="false">
                        <template v-slot:title>
                            Details
                        </template>
                    </collapsable-header>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-1/3 text-v3-gray-400">
                        Quick Reference
                        <span class="text-v3-gray-100" v-if="isActionRequired('quick_reference')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left w-full">
                        <property-or-field
                            :action="action"
                            fieldId="quick_reference"
                            :show-label="false"
                            v-model="formData['quick_reference']">
                        </property-or-field>
                        <div v-show="errors.has('quick_reference')" class="text-red-600 text-sm">{{ errors.first('quick_reference') }}</div>
                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Specifications
                        <span class="text-v3-gray-100" v-if="isActionRequired('specifications')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="specifications"
                            :show-label="false"
                            v-model="formData['specifications']">
                        </property-or-field>
                        <div v-show="errors.has('specifications')" class="text-red-600 text-sm">{{ errors.first('specifications') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Delivery Method
                        <span class="text-v3-gray-100" v-if="isActionRequired('delivery_method')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="delivery_method"
                            :show-label="false"
                            v-model="deliveryMethod">
                        </property-or-field>
                        <div v-show="errors.has('delivery_method')" class="text-red-600 text-sm">{{ errors.first('delivery_method') }}</div>


                    </div>
                    <div v-if="deliveryMethod === 'pick_up'" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Supplier
                        <span class="text-v3-gray-100" v-if="isActionRequired('supplier')">*</span>
                    </div>
                    <div v-if="deliveryMethod === 'pick_up'" class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="supplier"
                            :show-label="false"
                            v-model="formData['supplier']">
                        </property-or-field>
                        <div v-show="errors.has('supplier')" class="text-red-600 text-sm">{{ errors.first('supplier') }}</div>


                    </div>
                    <div v-if="deliveryMethod === 'pick_up'" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Pick Up Location
                        <span class="text-v3-gray-100" v-if="isActionRequired('pick_up_location')">*</span>
                    </div>
                    <div v-if="deliveryMethod === 'pick_up'" class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="pick_up_location"
                            :show-label="false"
                            v-model="formData['pick_up_location']">
                        </property-or-field>
                        <div v-show="errors.has('pick_up_location')" class="text-red-600 text-sm">{{ errors.first('pick_up_location') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Booking Required
                        <span class="text-v3-gray-100" v-if="isActionRequired('booking_delivery')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="booking_delivery"
                            :show-label="false"
                            v-model="formData['booking_delivery']">
                        </property-or-field>
                        <div v-show="errors.has('booking_delivery')" class="text-red-600 text-sm">{{ errors.first('booking_delivery') }}</div>

                    </div>
                </div>
                <div class="grid grid-cols-2 auto-rows-min">
                    <div class="col-span-2">
                        <collapsable-header class="col-span-2" :collapsable="false">
                            <template v-slot:title>
                                Contract Info
                            </template>
                        </collapsable-header>
                    </div>
                    <div v-if="hasField('client_id')" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Client
                        <span class="text-v3-gray-100" v-if="isActionRequired('client_id')">*</span>
                    </div>
                    <div v-if="hasField('client_id')" class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="client_id"
                            :show-label="false"
                            v-model="clientId">
                        </property-or-field>
                        <div v-show="errors.has('client_id')" class="text-red-600 text-sm">{{ errors.first('client_id') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Project
                        <span class="text-v3-gray-100" v-if="isActionRequired('project_id')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="project_id"
                            :show-label="false"
                            v-model="projectId"
                            depends-on="client_id"
                            :depends-on-value="clientId"
                        >
                        </property-or-field>
                        <div v-show="errors.has('project_id')" class="text-red-600 text-sm">{{ errors.first('project_id') }}</div>

                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Contract
                        <span class="text-v3-gray-100" v-if="isActionRequired('contract_id')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="site_id"
                            :show-label="false"
                            v-model="formData['site_id']"
                            depends-on="project_id"
                            :depends-on-value="projectId">
                        </property-or-field>
                        <div v-show="errors.has('site_id')" class="text-red-600 text-sm">{{ errors.first('site_id') }}</div>


                    </div>
                    <div v-if="hasField('site_manager_id')" class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Site Manager
                        <span class="text-v3-gray-100" v-if="isActionRequired('site_manager_id')">*</span>
                    </div>
                    <div v-if="hasField('site_manager_id')" class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="site_manager_id"
                            :show-label="false"
                            v-model="formData['site_manager_id']"
                            depends-on="client_id"
                            :depends-on-value="clientId">
                        </property-or-field>
                        <div v-show="errors.has('site_manager_id')" class="text-red-600 text-sm">{{ errors.first('site_manager_id') }}</div>


                    </div>
                </div>
                <div class="grid grid-cols-2 auto-rows-min">
                        <collapsable-header class="col-span-2" :collapsable="false">
                            <template v-slot:title>
                                Schedule
                            </template>
                        </collapsable-header>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Schedule File
                        <span class="text-v3-gray-100" v-if="isActionRequired('scheduled_file')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="scheduled_file"
                            :show-label="false"
                            v-model="formData['scheduled_file']">
                        </property-or-field>
                        <div v-show="errors.has('schedule_file')" class="text-red-600 text-sm">{{ errors.first('schedule_file') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Expected By Date
                        <span class="text-v3-gray-100" v-if="isActionRequired('expected_by_date')">*</span>
                    </div>
                    <div class="px-3 py-2 text-sm text-left flex">
                        <div class="flex w-full">
                            <property-or-field
                                :action="action"
                                fieldId="expected_by_date"
                                :show-label="false"
                                v-model="formData['expected_by_date']">
                            </property-or-field>
                        </div>
                        <div v-show="errors.has('expected_by_date')" class="text-red-600 text-sm">{{ errors.first('expected_by_date') }}</div>


                    </div>
                </div>
                <div class="grid grid-cols-2 auto-rows-min">
                    <collapsable-header class="col-span-2" :collapsable="false">
                        <template v-slot:title>
                            Attachments & Notes
                        </template>
                    </collapsable-header>

                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Supplier Notes
                        <span class="text-v3-gray-100" v-if="isActionRequired('additional_notes')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="additional_notes"
                            :show-label="false"
                            v-model="formData['additional_notes']">
                        </property-or-field>
                        <div v-show="errors.has('additional_notes')" class="text-red-600 text-sm">{{ errors.first('additional_notes') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Procurement Notes
                        <span class="text-v3-gray-100" v-if="isActionRequired('procurement_notes')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="procurement_notes"
                            :show-label="false"
                            v-model="formData['procurement_notes']">
                        </property-or-field>
                        <div v-show="errors.has('procurement_notes')" class="text-red-600 text-sm">{{ errors.first('procurement_notes') }}</div>


                    </div>
                    <div class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left text-v3-gray-400">
                        Attachment
                        <span class="text-v3-gray-100" v-if="isActionRequired('attachment')">*</span>
                    </div>
                    <div class="whitespace-nowrap px-3 py-2 text-sm text-left">
                        <property-or-field
                            :action="action"
                            fieldId="attachment"
                            :show-label="false"
                            v-model="formData['attachment']">
                        </property-or-field>
                        <div v-show="errors.has('attachment')" class="text-red-600 text-sm">{{ errors.first('attachment') }}</div>

                    </div>
                </div>
            </div>

            <new-order-items-pagination ref="orderItems" :create-item-action="createOrderItemAction" :collapsable="false" class="my-2">

            </new-order-items-pagination>

            <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

        </large-model>
        </portal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Header from "@/v2/components/Header.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import SplitOrderForm from "@/v3/components/forms/SplitOrderForm.vue";
import GoodsInNoteForm from "@/v3/components/forms/GoodsInNoteForm.vue";
import OrderItemsPagination from "@/v3/components/pagination/OrderItemsPagination.vue";
import NewOrderItemsPagination from "@/v3/components/pagination/NewOrderItemsPagination.vue";
let follow = Siren.Helper.follow;
export default {

    inject: ["$validator"],
    name: "CreateOrderForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            clientId: null,
            projectId: null,
            deliveryMethod: 'delivery'
        }
    },
    components: {
        NewOrderItemsPagination,
        OrderItemsPagination, GoodsInNoteForm, SplitOrderForm,
        CustomButton,
        CollapsableHeader,
        Header,
        BlankHeader,
        PropertyOrField,
        LargeModel,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      },
        createOrderItemAction:{
            type: Object,
            default: null
        }
    },
    mounted(){
        this.$emit('loaded');
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            if(payload['client_id'] === undefined)
            {
                Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("current-user")
                    )
                    .then(res => {
                        this.clientId = res.body.properties.get("client_id");
                    }, this);
            }
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.submittingModal = true;
                this.formData['client_id'] = this.clientId;
                this.formData['project_id'] = this.projectId;
                this.formData['delivery_method'] = this.deliveryMethod;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    if (this.redirectOnSuccess) {
                        this.$router.go(0);
                    }
                    let action = res.body.actions.filter(action =>action.name === 'add-new-item').first();
                    this.$refs.orderItems.updateItems(action).then(res2 => {
                        this.displayModal = false;
                        this.submittingModal = false;

                        this.$store.dispatch(
                            "setMessage",
                            this.messages(res).entity.properties.get("success")
                        );
                        console.log(res);
                        let selfLink = res.body.links.filter(link => link.rels.contains('self')).first().href;
                        let parts = selfLink.split('/api');
                        Siren.Client.addHeader(
                            "Authorization",
                            `Bearer ${this.accessToken}`
                        );
                        Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                            this.$emit('success', response);
                        }).catch(function(){

                        });

                    }).catch(error => {
                       this.displayModal = false;
                        this.submittingModal = false;

                        this.$store.dispatch(
                            "setMessage",
                            this.messages(res).entity.properties.get("success")
                        );
                        let selfLink = res.body.links.filter(link => link.rels.contains('self')).first().href;
                        let parts = selfLink.split('/api');
                        Siren.Client.addHeader(
                            "Authorization",
                            `Bearer ${this.accessToken}`
                        );
                        Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                            this.$emit('success', response);
                        }).catch(function(){

                        });
                    })

                    // this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else if (error.status === 403 || error.status === 401) {
                        let errorMessage = "You do not have permission to " + this.action.title;
                        if (error.response.body.message) {
                            errorMessage = error.response.body.message;
                        }
                        alert(errorMessage);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        isActionRequired(fieldId){
            if (!this.action) {
                return false;
            }
            let field = this.action.fields.filter(field => field.name === fieldId).first();
            if(field){
                if(field.validation != null) {
                    return field.validation.includes('required')
                }
            }
            return false;
        },
        hasField(fieldId){
            if (!this.action) {
                return false;
            }
            return this.action.fields.filter(field => field.name === fieldId).first();
        }
    },
}
</script>

<style scoped>

</style>
